<template>
  <div class="ra-basic ra-basic-text ra-center">
    <h2>404 | Page Not Found</h2>
    <p>
      Take this Banana of sympathy
      <br />
      <img src="@/assets/not-found/banana.png">
      <br />
      ...if you can
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  metaInfo: {
    title: '404',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Page Not Found'
      },
      { name: 'og:title', content: '404 | Roman Almazov' },
      { name: 'twitter:title', content: '404 | Roman Almazov' },
      { name: 'og:description', content: 'Page Not Found' },
      { name: 'twitter:description', content: 'Page Not Found' }
    ]
  }
}
</script>

<style scoped>
  h2 {
    font-size: 60px;
  }

  .ra-center {
    text-align: center;
  }
</style>
